import styled from 'styled-components';

import { Heading } from 'src/modules/text';

export default {
  HeadingContainer: styled.div`
    margin: 70px 0px 40px 0px;
  `,
  Heading: styled(Heading)`
    margin-bottom: 10px;
    text-align: center;
    font-size: 32px;
  `,
  SubHeading: styled.div`
    margin-bottom: 40px;
    text-align: center;
  `,
  Text: styled.div`
    text-align: center;
  `,
};
