import React from 'react';
import PropTypes from 'prop-types';

import { convertMDToInnerHTML } from 'src/modules/text';
import { colors } from 'src/styles';
import { Section } from 'src/modules/layout';
import targetImage from 'src/assets/images/target.svg';
import S from './about.MissionSection.Styled';

const MissionSection = ({ heading, text }) => (
  <Section withPadding backgroundColor={colors.background.lightblue}>
    <S.PageWrap>
      <S.Content>
        <S.Heading>{heading}</S.Heading>
        <S.Text dangerouslySetInnerHTML={convertMDToInnerHTML(text)}></S.Text>
      </S.Content>
      <S.Image>
        <img src={targetImage} alt="Target illustration" />
      </S.Image>
    </S.PageWrap>
  </Section>
);

MissionSection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MissionSection;
