import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from 'src/modules/layout';
import { colors } from 'src/styles';

import HeaderSection from './about.HeaderSection';
import MissionSection from './about.MissionSection';
import ValuesSection from './about.ValuesSection';
import TeamSection from './about.TeamSection';
import WhoSection from './about.WhoSection';

const AboutPageTemplate = ({
  headerSectionData,
  missionSectionData,
  valuesSectionData,
  teamSectionData,
  whoSectionData,
}) => (
  <Layout headOptions={{ title: 'Ποιοι είμαστε' }} footerOptions={{ backgroundColor: colors.background.lightblue }}>
    <HeaderSection {...headerSectionData} />
    <MissionSection {...missionSectionData} />
    <ValuesSection {...valuesSectionData} />
    <TeamSection {...teamSectionData} />
    <WhoSection {...whoSectionData} />
  </Layout>
);

AboutPageTemplate.propTypes = {
  headerSectionData: PropTypes.shape(HeaderSection.propTypes).isRequired,
  missionSectionData: PropTypes.shape(MissionSection.propTypes).isRequired,
  valuesSectionData: PropTypes.shape(ValuesSection.propTypes).isRequired,
  teamSectionData: PropTypes.shape(TeamSection.propTypes).isRequired,
  whoSectionData: PropTypes.shape(WhoSection.propTypes).isRequired,
};

export default AboutPageTemplate;
