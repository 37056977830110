import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { colors } from 'src/styles';

export default {
  Entry: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px 0px;
  `,
  Details: styled.div`
    margin-left: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${colors.main.grey1};
  `,
  Name: styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  `,
  Description: styled.div`
    margin-bottom: 25px;
  `,
  Heading: styled(Heading)`
    margin-bottom: 50px;
  `,
  LogoContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid ${colors.main.grey1};
  `,
};
