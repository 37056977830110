import React from 'react';
import PropTypes from 'prop-types';

import { FixedImage } from 'src/modules/image';
import { convertMDToInnerHTML } from 'src/modules/text';
import { Button } from 'src/modules/input';
import { Section, PageWrap } from 'src/modules/layout';
import S from './about.WhoSection.Styled';

const Entry = ({ name, description, image, link }) => (
  <S.Entry>
    <S.LogoContainer>
      <FixedImage imgStyle={{ width: 45 }} image={image} />
    </S.LogoContainer>
    <S.Details>
      <S.Name>{name}</S.Name>
      <S.Description dangerouslySetInnerHTML={convertMDToInnerHTML(description)}></S.Description>
      <Button href={link} secondary>
        Μαθε περισσοτερα
      </Button>
    </S.Details>
  </S.Entry>
);

Entry.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

const WhoSection = ({ heading, entries }) => (
  <Section withPadding>
    <PageWrap>
      <S.Heading>{heading}</S.Heading>
      {entries.map(entry => (
        <Entry
          key={entry.name}
          name={entry.name}
          description={entry.description}
          image={entry.image}
          link={entry.link}
        />
      ))}
    </PageWrap>
  </Section>
);

WhoSection.propTypes = {
  heading: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.object,
    }),
  ).isRequired,
};

export default WhoSection;
