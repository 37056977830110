import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import AboutPageTemplate from './about/about.Template';

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <AboutPageTemplate
      headerSectionData={frontmatter.headerSectionData}
      missionSectionData={frontmatter.missionSectionData}
      valuesSectionData={frontmatter.valuesSectionData}
      teamSectionData={frontmatter.teamSectionData}
      whoSectionData={frontmatter.whoSectionData}
    />
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        headerSectionData {
          heading
          subHeading
          text
        }
        missionSectionData {
          heading
          text
        }
        valuesSectionData {
          heading
          subHeading
          values {
            image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            width
            title
          }
        }
        teamSectionData {
          heading
          members {
            name
            position
            linkedin
            image {
              childImageSharp {
                fixed(width: 130, height: 130) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        whoSectionData {
          heading
          entries {
            name
            description
            link
            image {
              childImageSharp {
                fixed(width: 45) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
