import styled from 'styled-components';
import { darken } from 'polished';

import LinkedIn from 'src/assets/inlineSVG/linkedin.svg';
import { Heading } from 'src/modules/text';
import { colors } from 'src/styles';

export default {
  Heading: styled(Heading)`
    margin-bottom: 50px;
    text-align: center;
  `,
  MembersContainer: styled.div`
    display: flex;
    justify-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
  `,
  TeamMember: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    border: solid 1px ${colors.main.grey1};
    width: 300px;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
  `,
  TeamMemberAvatar: styled.div`
    position: relative;
    min-width: 130px;
    min-height: 130px;
    margin-right: 10px;
  `,
  TeamMemberDetails: styled.div``,
  TeamMemberName: styled.div`
    font-size: 23px;
    color: ${colors.main.blue};
    font-weight: bold;
  `,
  TeamMemberPosition: styled.div``,
  LinkedIn: styled(LinkedIn)`
    position: absolute;
    bottom: 2px;
    left: 80px;
    width: 30px;

    path {
      fill: ${colors.main.blue};
      transition: fill 0.25s ease;
    }

    &:hover {
      path {
        fill: ${darken(0.1, colors.main.blue)};
      }
    }
  `,
};
