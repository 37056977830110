import React from 'react';
import PropTypes from 'prop-types';

import { FluidImage } from 'src/modules/image';
import { Section, PageWrap } from 'src/modules/layout';
import S from './about.ValuesSection.Styled';

const ValueItem = ({ image, title, width }) => (
  <S.ValueItem>
    <S.ValueImage style={{ width }}>
      <FluidImage image={image} />
    </S.ValueImage>
    <S.ValueTitle>{title}</S.ValueTitle>
  </S.ValueItem>
);

ValueItem.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }).isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

const ValuesSection = ({ heading, subHeading, values }) => (
  <Section withPadding>
    <PageWrap>
      <S.HeadingContainer>
        <S.Heading>{heading}</S.Heading>
        <S.SubHeading>{subHeading}</S.SubHeading>
      </S.HeadingContainer>
      <S.ValuesContainer>
        {values.map(value => (
          <ValueItem key={value.title} image={value.image} title={value.title} width={value.width} />
        ))}
      </S.ValuesContainer>
    </PageWrap>
  </Section>
);

ValuesSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.obj,
      title: PropTypes.string,
    }),
  ),
};

ValuesSection.defaultProps = {
  values: [],
};

export default ValuesSection;
