import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { colors } from 'src/styles';

export default {
  HeadingContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    text-align: center;
  `,
  Heading: styled(Heading)`
    margin-bottom: 10px;
  `,
  SubHeading: styled.div`
    max-width: 450px;
  `,
  ValuesContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 850px;
    margin: 0 auto;
  `,
  ValueItem: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-width: 180px;
    margin: 35px;
  `,
  ValueImage: styled.div``,
  ValueTitle: styled.div`
    font-size: 23px;
    font-weight: bold;
    color: ${colors.main.blue};
  `,
};
