import styled from 'styled-components';

import { breakpoints } from 'src/styles';
import { Heading } from 'src/modules/text';
import { PageWrap } from 'src/modules/layout';

export default {
  PageWrap: styled(PageWrap)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      justify-content: space-between;
      flex-direction: row;
    }
  `,
  Content: styled.div``,
  Heading: styled(Heading)`
    margin-bottom: 20px;
  `,
  Text: styled.div`
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: 40px;
    @media only screen and (min-width: ${breakpoints.xs.max}) {
      margin-right: 20px;
      margin-bottom: 0px;
    }
  `,
  Image: styled.div`
    img {
      width: 250px;
    }
  `,
};
