import React from 'react';
import PropTypes from 'prop-types';

import { FixedImage } from 'src/modules/image';
import { colors } from 'src/styles';
import { Section, PageWrap } from 'src/modules/layout';
import S from './about.TeamSection.Styled';

const TeamMember = ({ name, position, linkedin, image }) => (
  <S.TeamMember>
    <S.TeamMemberAvatar>
      <FixedImage imgStyle={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%' }} image={image} />
      <a href={linkedin} target="_blank" rel="noopener noreferrer">
        <S.LinkedIn />
      </a>
    </S.TeamMemberAvatar>
    <S.TeamMemberDetails>
      <S.TeamMemberName>{name}</S.TeamMemberName>
      <S.TeamMemberPosition>{position}</S.TeamMemberPosition>
    </S.TeamMemberDetails>
  </S.TeamMember>
);

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

const TeamSection = ({ heading, members }) => (
  <Section withPadding backgroundColor={colors.background.lightblue}>
    <PageWrap>
      <S.Heading>{heading}</S.Heading>
      <S.MembersContainer>
        {members.map(member => (
          <TeamMember key={member.name} {...member} />
        ))}
      </S.MembersContainer>
    </PageWrap>
  </Section>
);

TeamSection.propTypes = {
  heading: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(TeamMember.propTypes)).isRequired,
};

export default TeamSection;
