import React from 'react';
import PropTypes from 'prop-types';

import { convertMDToInnerHTML } from 'src/modules/text';
import { Section, PageWrap } from 'src/modules/layout';
import S from './about.HeaderSection.Styled';

const HeaderSection = ({ heading, subHeading, text }) => (
  <Section withPadding>
    <PageWrap>
      <S.HeadingContainer>
        <S.Heading>{heading}</S.Heading>
        <S.SubHeading>{subHeading}</S.SubHeading>
      </S.HeadingContainer>
      <S.Text dangerouslySetInnerHTML={convertMDToInnerHTML(text)}></S.Text>
    </PageWrap>
  </Section>
);

HeaderSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default HeaderSection;
